/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 500px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 66.26506024096386%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAwABAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABJx5CyGy//8QAGxAAAQQDAAAAAAAAAAAAAAAAAAECERIhIjH/2gAIAQEAAQUCa6CbFoK6ouF7/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHBAAAQMFAAAAAAAAAAAAAAAAAAEQQRIhQoGR/9oACAEBAAY/AhLQZdNFMN//xAAdEAEAAgICAwAAAAAAAAAAAAABABEhMUFRYXGR/9oACAEBAAE/IctyH24BKxyjegj1HCt10lXc3N3if//aAAwDAQACAAMAAAAQby//xAAYEQADAQEAAAAAAAAAAAAAAAAAAREhMf/aAAgBAwEBPxCqHMrP/8QAGBEAAgMAAAAAAAAAAAAAAAAAAAERIUH/2gAIAQIBAT8QhlPD/8QAHRABAQACAgMBAAAAAAAAAAAAAREAMSFhQXGBof/aAAgBAQABPxAfM/ItdMI443gq6J7nzHFgXTH8x6aBeRNYyAn1OXJjUsz/2Q=='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"koi fish\"\n        title=\"koi fish\"\n        src=\"/static/1ab70775cb10830b767a1ab821cd6bf6/10bbb/colorful-koi.jpg\"\n        srcset=\"/static/1ab70775cb10830b767a1ab821cd6bf6/4d5fb/colorful-koi.jpg 166w,\n/static/1ab70775cb10830b767a1ab821cd6bf6/558f0/colorful-koi.jpg 333w,\n/static/1ab70775cb10830b767a1ab821cd6bf6/10bbb/colorful-koi.jpg 500w\"\n        sizes=\"(max-width: 500px) 100vw, 500px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "As you might expect, words over time get a little altered from their original sound. Think of the old grapevine game you used to play as a kid - by the end, the original phrase was seriously changed."), "\n", React.createElement(_components.p, null, "Now lets look at the original term for koi which is the Japanese word “nishikigoi”, which literally means brocaded carp. "), "\n", React.createElement(_components.p, null, "You'll notice that the end of the word looks very similar to the modern word 'koi' -that's because through time and through thousands of conversations about these fish the original term got shortened to 'koi' from the ending 'goi'."), "\n", React.createElement(_components.p, null, "Now the exact origins of the actual fish are still unknown, yet there is a common belief that they originated around the areas of Western Asia and Persia. "), "\n", React.createElement(_components.p, null, "In the 17th century Chinese farmers were keeping carp in their rice paddies and that practice was later incorporated in Japan as well."), "\n", React.createElement(_components.p, null, "Koi are essentially ornamental versions of carp that, through time and color mutations, began displaying colors beyond that of the drab brown of carp. "), "\n", React.createElement(_components.p, null, "Those colorful ones were selected by hand to be bred to produce even more of these curious colored carp and eventually we got the art of koi breeding."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
